/*------------------------------------*\
  #deafult.html 首頁
\*------------------------------------*/
.homeBlock {
  img {
    width: 100%;
  }
}
.homeSlide {
  width: 100%;
  .slick-prev, .slick-next {
    z-index: 2;
    width: 30px;
    height: 30px;
    &:before{
      font-size: 30px;
    }
  }
  .slick-prev {
    left: 15px;
  }
  .slick-next {
    right: 15px;
  }
}
//@media (min-width: $tablet-width) {
//  .homeSlide {
//    .slick-prev, .slick-next {
//      width: 40px;
//      height: 40px;
//      &:before{
//        font-size: 40px;
//      }
//    }
//  }
//}
//@media (min-width: $desktop-width) {
//  .homeSlide {
//    .slick-prev, .slick-next {
//      width: 50px;
//      height: 50px;
//      &:before{
//        font-size: 50px;
//      }
//    }
//  }
//}