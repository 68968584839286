.buyStep {
    background: $bg_gray;
    text-align: center;
    margin: 15px;
    li {
        display: inline-block;
        color: $mid_gray;
        line-height: 32px;
        font-size: 14px;
        padding: 0 8px;
        margin: 0;
        position: relative;
        &:after {
            content: '';
            display: block;
            position: absolute;
            border-left: solid 8px $mid_gray;
            border-top: solid 5px transparent;
            border-bottom: solid 5px transparent;
            right: -7px;
            top: 11px;
        }
        &:last-child {
            &:after {
                display: none;
            }
        }
        &.active {
            color: #000;
        }
    }
}

.cartbox {
    display: table;
    border-bottom: solid 1px $mid_gray;
    margin: 0 15px;
    width: calc(100% - 30px);/*sonia*/
    label {
        margin: 0 10px 0 0;
    }
    &.cartbox-wish {
        .cartbox_right_box {
            &:last-of-type,
            &:nth-last-of-type(2) {
                display: inline-block;
            }
        }
        .btn,
        .btn_second {
            width: 100%;
        }
        .btn{
            // padding: 0 12px;
            padding: 0 6px;
        }
    }
}

.cartbox_left {
    display: table-cell;
    width: 50%;
    padding: 15px 15px 15px 0;
    vertical-align: top;
    img {
        width: 100%;
    }
}

.cartbox_right {
    display: table-cell;
    padding: 15px 15px 15px 0;
}

.cartbox_right_name {
    font-weight: bold;
    margin: 0 0 10px;
    font-size: 14px;
}

.cartbox_right_event {
    padding: 5px;
    // background: #c5bab5;
    background: #750202;
    color: #fff;
    font-size: 15px;
    margin: 0 0 10px;
    font-size: 13px;
    display: inline-block;
}

.cartbox_right_detail {
    span {
        margin: 0 15px 0 0;
        &:last-child {
            margin: 0;
        }
    }
}

.cartbox_right_box {
    margin: 0 0 10px;
    label,
    span {
        font-size: 13px;
    }
}

.total {
    text-align: right;
    background: $bg_gray;
    padding: 15px;
    margin: 0 15px 45px;
    border-bottom: solid 1px $line_gray;
    p {
        span {
            color: #000;
            font-weight: bold;
            margin: 0 3px;
        }
    }
    ul {
        text-align: left;
    }
    li {
        span {
            &:first-child {
                font-weight: bold;
                margin-right: 30px;
                line-height: 30px;
            }
        }
        &:last-child {
            span {
                font-size: 20px;
                line-height: 40px;
            }
        }
    }
}
// 組合商品
.cartbox_submenu{
    width: calc(100% - 30px);
    margin: 0 15px;
    .cartbox{
        display: none;
        background: #eee;
        margin: 0;
        width: 100%;
        padding: 0 15px;
    }
    &.active{
        .cartbox{
            display: block;
        }
    }
}
.cartbox_submenu_title{
    text-align: center;
    background: #eee;
    border-bottom: 1px solid $line_gray;
    &:after{
        vertical-align: middle;
        content: "";
        display: inline-block;
        border: 6px solid #eee;
        border-top: 8px solid #888;
    }
    &.active{
        &:after{
            margin-top: 3px;
            vertical-align: top;
            border: 6px solid #eee;
            border-bottom: 8px solid #888;
        }
    }
}
@media (min-width: 600px) {
    .cartbox_left {
        width: 20%;
    }
    .cartbox_right {
        width: 80%;
    }
}

@media (min-width: $tablet-width) {
    // .cartbox_right_name {
    //     display: inline-block;
    //     width: 25%;
    // }
    // .cartbox_right_event {
    //     display: inline-block;
    //     width: 25%;
    // }
    .cartbox_right_name {
        font-size: 15px;
    }
    .cartbox_right_event {
        font-size: 14px;
    }
    .cartbox_right_detail {
        span {
            font-size: 14px;
        }
    }
    .cartbox_right_box {
        label,
        span {
            font-size: 14px;
        }
    }
}

@media (min-width: $desktop-width) {
    .cartbox_left {
        width: 12%;
    }
    .cartbox_right {
        width: 88%;
    }
    .cartbox_right_box {
        display: inline-block;
        // width: calc(17% - 10px);
        // margin: 0 10px 0 0;
        width: 17%;
        padding-right: 10px;
        margin: 0;
        vertical-align: middle;
        text-align: center;
        &:first-child {
            // width: calc(30% - 10px);
            width: 29%;
            text-align: left;
        }
        // &:last-child {
        //     margin: 0;
        // }
    }
    .cartbox {
        &.cartbox-mo {
            .cartbox_right_box {
                // width: calc(33% - 10px);
                width: 20%;
                &:first-child {
                    width: 37%;
                }
            }
        }
        &.cartbox-wish {
            .cartbox_left {
                width: 18%;
            }
            .cartbox_right {
                width: 82%;
            }
            .cartbox_right_box {
                // width: calc(22.5% - 10px);
                width: 20%;
                &:first-child {
                    // width: calc(28% - 10px);
                    width: 37%;
                }
            }
            .btn,
            .btn_second {
                width: auto;
            }
        }
    }
}