/*------------------------------------*\
  #activity
\*------------------------------------*/

.activity_note {
    border: 1px solid #333;
    margin: 0 5px;
    padding: 15px;
    text-align: center;
}

.activity_title {
    font-size: 20px;
    font-weight: bold;
}

.activity_time {
    font-size: 14px;
}

.discountBox {
    display: block;
    position: relative;
    float: left;
    width: calc(100% - 10px); // width: calc(50% - 10px);
    margin: 20px 5px;
    border: 1px solid #000;
    box-shadow: 4px 4px 3px rgba(20%, 20%, 40%, 0.5);
    cursor: pointer;
    .discountBox_cover {
        background-color: #ddd;
        opacity: 0.8;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.discount_cnt {
    vertical-align: middle;
    text-align: center;
}

.discount_sum {
    float: left;
    width: 30%;
    background-color: #ddd;
    font-size: 15px;
    height: 100px;
    line-height: 100px;
    span {
        font-size: 30px;
    }
}

.discount_note {
    float: left;
    width: 70%;
    height: 100px;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // align-items: center;
    overflow: auto;
}

.discount_clickOn {
    position: relative;
    text-align: center;
    height: 50px;
    line-height: 50px;
    background-color: #000;
    color: #fff;
    box-shadow: 0px -3px 2px rgba(20%, 20%, 40%, 0.5);
}

.discount_qty {
    position: absolute;
    right: 10px;
    bottom: 20px;
    width: 55px;
    height: 55px;
    font-size: 12px;
    background-color: red;
    border: 1px solid red;
    border-radius: 50%;
    box-shadow: 3px 3px 2px rgba(214, 38, 7, 0.5);
    span {
        &:first-child {
            height: 25px;
            display: block;
            line-height: 30px;
        }
        &:last-child {
            display: block;
            height: 25px;
            line-height: 25px;
        }
    }
}

@media (min-width: $tablet-width) {
    .discountBox {
        width: calc(50% - 10px);
    }
    // .discount_sum {
    //     font-size: 13px;
    //     width: 30%;
    //     span {
    //         font-size: 26px;
    //     }
    // }
    // .discount_note {
    //     width: 70%;
    // }
}

@media (min-width: $desktop-width) {
    // .discount_sum {
    //     font-size: 15px;
    //     span {
    //         font-size: 30px;
    //     }
    // }
}