.store_img {
    padding: 0 1.5rem;
    img {
        width: 100%;
    }
}
.store_info {
    padding: 15px 1.5rem;
    position: relative;
    p {
        font-size: 14px;
        margin: 0 0 10px;
        padding-left: 23px;
        position: relative;
        i {
            position: absolute;
            top: 4px;
            left: 3px;
        }
        &.store_name {
            font-weight: bold;
            font-size: 16px;
            padding-left: 0;
        }
        
    }
    .btn_second {
        display: block;
        margin-bottom: 30px;
    }
}
.store_map {
    padding: 15px 1.5rem 15px 0;
    iframe {
        width: 100%;
    }
}

@media (min-width: $tablet-width) {
    .store_info {
        .btn_second {
            position: absolute;
            bottom: 0.75rem;
            display: block;
            padding: 0;
            margin-bottom: 0;
            width: calc(100% - 3.75rem);
        }
    }
}
@media (min-width: $desktop-width) {
    .store_info {
        text-align: center;
        p {
            i {
                position: relative;
                top: auto;
                left: auto;
                margin: 0 5px;
            }
        }
    }
}