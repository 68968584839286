/*------------------------------------*\
  #font
\*------------------------------------*/
@font-face {
	font-family: "NotoSansTC";
	font-weight: normal;
	font-style: normal;
	src: url('fonts/NotoSansTC-Regular.otf');
	// font-display: swap;
	font-display: auto;
}
@font-face {
	font-family: "NotoSansTC";
	font-weight: bold;
	font-style: normal;
	src: url('fonts/NotoSansTC-Bold.otf');
	font-display: auto;
}
@font-face {
	font-family: "NotoSansTC";
	font-weight: lighter;
	font-style: normal;
	src: url('fonts/NotoSansTC-Light.otf');
	font-display: auto;
}
@font-face {
	font-family: "Blinker";
	font-weight: normal;
	font-style: normal;
	src: url('fonts/Blinker-Regular.ttf');
	font-display: auto;
}
@font-face {
	font-family: "Blinker";
	font-weight: bold;
	font-style: normal;
	src: url('fonts/Blinker-Bold.ttf');
	font-display: auto;
}