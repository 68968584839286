header {
    height: $header_height;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    //box-shadow: 0 0 1px 1px rgba(125, 125, 125, 0.5);
    z-index: 20;
    background: #fff;
}

.header_inner {
    position: relative;
}

.header_icon {
    display: block;
    width: $header_height;
    height: $header_height;
    text-align: center;
    vertical-align: middle;
    color: $icon_color;
    //i {
    //    line-height: $header_height;
    //    pointer-events: none;
    //    font-size: 25px;
    //}
    img{
        margin-top: 11px;
        width: 28px;
    }
    &.header_menu {
        img{
            margin-top: 9px;
            width: 32px;
        }
    }
    &.header_sign{
        width: 40px;
    }
    &.header_search {
        // width: 30px;
        width: 40px;
    }
    &.header_cart {
        width: 40px;
        position: relative;
        span {
            display: block;
            position: absolute;
            width: 21px;
            height: 21px;
            line-height: 21px;
            text-align: center;
            color: #fff;
            //background: red;
            background: $orange;
            font-size: 12px;
            border-radius: 50% 50%;
            top: 5px;
            right: 3px;
        }
    }
    &:focus {
        color: $icon_color;
    }
    &:active {
        color: $icon_color;
    }
}

.logo {
    position: absolute;
    left: 50%;
    width: 50%;
    height: $header_height;
    margin-left: -30%;
    text-decoration: none;
    text-align: center;
    padding-left: 30px;
    p {
        display: flex;
        align-items: center;
        justify-content: center;
        height: $header_height;
    }
    img {
        // height: 45%;
        height: 70%;
        display: block;
    }
}

.offcanvas {
    position: fixed;
    width: 80vw;
    transform: translateX(-80vw);
    top: $header_height;
    left: 0;
    transition: transform .5s;
    height: calc(100vh - #{$header_height});
    padding-bottom: 2px;
    overflow-y: auto;
    background: #fff;
    z-index: 10;
    &.active {
        transform: translateX(0);
    }
    >ul {
        >li {
            border-bottom: solid 1px $line_gray;
            >a {
                position: relative;
                line-height: 60px;
                &.js-toggle-trigger:before {
                    content: '+';
                    line-height: 60px;
                    position: absolute;
                    right: 20px;
                    top: 0;
                }
                &.active {
                    border-bottom: $line_gray;
                    &:before {
                        content: '-';
                    }
                }
            }
            >ul{
                >li{
                    >a.js-toggle-trigger{//new:第三層
                        position: relative;
                        
                        &:before {
                            content: '+';
                            line-height: 40px;
                            position: absolute;
                            right: 20px;
                            top: 0;
                        }
                        &.active {
                            border-bottom: $line_gray;
                            &:before {
                                content: '-';
                            }
                        }
                    }
                    >ul.submenu{
                        margin-left: -40px;
                        // background: #ccc;
                        li a{
                            padding-left: 60px;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
    a {
        display: block;
        padding: 0 20px;
    }
    .submenu {
        display: none;
        // margin-left: 20px;
        // margin-bottom: 10px;
        padding-left: 20px;
        padding-bottom: 10px;
        background: #7d7d7d;
        &.active {
            display: block;
        }
        a {
            line-height: 40px;
            // color: #888;
            color: #fff;
        }
    }
}

.searchWrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 2;
    display: none;
    padding: $header_height 0 0;
    &.active {
        display: block;
    }
    .searchWrap_inner {
        padding: 20px;
        background-color: #fff;
    }
    input {
        //width: calc(100% - 45px);
        width: calc(100% - 40px);
        border: solid 1px $icon_color;
        display: inline-block;
        margin-top: 5px;
        border-radius: 15px;
    }
    a {
        display: inline-block;
        width: 35px;
        line-height: 40px;
        text-align: center;
        color: $icon_color;
    }
    //i{
    //    font-size: 20px;
    //}
    img{
        margin-bottom: 5px;
        width: 30px;
        vertical-align: middle;
    }
}
.hd_tag {
    display: none;
    min-width: 400px;
    font-size: 13px;
    span{
        color: $orange;
    }
    a:link {
        margin: 5px;
        //color: #898989;
        width: auto;
        line-height: 0;
    }
}
.mainMenu {
    position: fixed;
    top: $header_height2;
    z-index: 20;
    width: 100%;
    text-align: center;
    background: #fff;
    //box-shadow: 0 0 1px 1px rgba(125, 125, 125, 0.5);
    font-size: .9em;
    a {
        line-height: 40px;
        display: block;
        transition: all .5s;
        padding: 0 30px 0 15px;
        font-family: "Blinker", "NotoSansTC";
        letter-spacing: 1px;
        &:hover {
            color: $mid_gray;
        }
    }
    li {
        float: left;
        position: relative;
        &:hover {
            .submenu {
                display: block;
            }
        }
    }
    .submenu {
        display: none;
        background: #fff;
        box-shadow: 0 2px 1px 1px rgba(125, 125, 125, 0.5);
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1;
        li {
            float: none;
            padding: 0;
            text-align: left;
        }
        a {
            //color: #888;
            display: block;
            padding: 0 20px;
            white-space: nowrap;
            font-size: .9em;
            &:hover {
                //background: #888;
                background: #c5bab5;
                color: #fff;
                // color: $main_black;
            }
        }
    }
}

@media (min-width: $tablet-width) {
    .searchWrap {
        display: block;
        width: 200px;
        height: 50px;
        left: auto;
        // right: 50px;
        right: 82px;
        top: 0;
        background: transparent;
        padding: 0;
        z-index: 25;
        .searchWrap_inner {
            padding: 5px 0 0;
        }
    }
    input {
        line-height: 22px;
    }
}

@media (min-width: $desktop-width) {
    header {
        box-shadow: 0 0 0 0 transparent;
        //height: $header_height2;
        height: 120px;
        box-shadow: 0 0 1px 1px rgba(125, 125, 125, 0.5);
    }
    .header_icon{
        width: 40px;
        img{
            margin-top: 12px;
            width: 25px;
        }
        &.header_cart {
            margin-right: 10px;
            span{
                right: -3px;
            }
        }
    }
    .searchWrap {
        //right: 120px;
        right: 110px;
        width: 400px;
        img{
            width: 25px;
        }
    }
    .logo {
        // left: 15px;
        left: 40px;
        margin-left: 0;
        background-position: 15px center;
        top: 5px;
        width: 135px;
        padding-left: 0;
        img{
            // height: 60%;
            height: 90%;
        }
    }
    .hd_tag{
        display: block;
    }
}
