.login {
    padding: 0 15px;
    margin-bottom: 30px;
    font-size: 14px;
    p {
        margin: 0 0 15px;
    }
}
.login + .login {
    background: #F3F3F3;
    padding-bottom: 30px;
    padding-top: 15px;
    
}
@media (min-width: $tablet-width) {
    .login {
        p {
            font-size: 16px;
        }
    }
}
@media (min-width: $desktop-width) {
    .loginwrap-big{
        width: 50%;
        float: left;
    }
    .loginwrap-big.login + .login {
        margin-top: 120px;
    
    }
}