/*------------------------------------*\
  #style
\*------------------------------------*/
.pdbox_info{
    display: none;
}

.popup_pdBox, .popup_pdCnt{
    position: fixed;
    display: none;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    
}

.slickWrap{
    position: relative;
    width: 90%;
    max-width: 450px;
    // height: 600px;
    img{
        width: 100%;
    }
}

.popup_slick_esc{
    display: block;
    position: absolute;
    // right: 0;
    // top: 0;
    top: 10px;
    right: 10px;
    z-index: 9000;
    // width: 40px;
    // height: 40px;
    // background-color: #fff;
    // background: url(../../images/zh-cht/icons/escape.png) no-repeat center center / 70% #eee;
    opacity: .5;
    &:hover{
        opacity: .8;
    }
    i{
        //font-size: 40px;
        font-size: 28px;
        color: #fff;
        background: $main_black;
        border-radius: 50%;
        border: 1px solid $main_black;
    }
}

.popup_slick_prev, .popup_slick_next{
    display: block;
    position: absolute;
    top: calc(50% - 20px);
    width: 40px;
    height: 60px;
    opacity: .5;
    &:hover{
        opacity: .8;
    }
}
.popup_slick_prev{
    left: 5px;
    //background: url(../../images/zh-cht/icons/left-arrow.png) no-repeat center center / 60% #ddd;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 2px solid $main_black;
    background: #fff;
    text-align: center;
    text-align: center;
    &:after{
        display: inline-block;
        content: "";
        width: 10px;
        height: 10px;
        margin-left: 4px;
        border-top: 2px solid $main_black;
        border-left: 2px solid $main_black;
        transform: rotate(-45deg);  
    }
}
.popup_slick_next{
    right: 5px;
    //background: url(../../images/zh-cht/icons/right-arrow.png) no-repeat center center / 60% #ddd;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 2px solid $main_black;
    background: #fff;
    text-align: center;
    text-align: center;
    &:after{
        display: inline-block;
        content: "";
        width: 10px;
        height: 10px;
        margin-right: 4px;
        border-top: 2px solid $main_black;
        border-right: 2px solid $main_black;
        transform: rotate(45deg);  
    }
}
.popup_pdBox_info{
    position: absolute;
    right: 10px;
    bottom: 10px;
    tr{
        a{
            display: block;
            background: #999;
            color: #fff;
            font-size: 13px;
            padding: 3px 5px;
            margin: 3px 0;
        }
        &:hover{
            a{
                background: #333;
                color: #fff;
            }
        }
    }
}
.popup_pdBox_infoPd_price{
    text-align: right;
}

.popup_pdCnt{
    .popup_slick_esc{
        position: fixed;
        right: 5%;
        top: 5vh;
    }
}

.pdCntWrap{
    position: relative;
    // width: 900px;
    width: 90%;
    max-height: 90vh;
    background: #fff;
    overflow-y: scroll;
}

.add_info{
    transform: translateY(-1000px);
    transition: transform 1s;
    // position: absolute;
    // top: 0;
    // right: 0;
    position: fixed;
    // bottom: 0;
    top: 5vh;
    right: 5vw;
    background: #fff;
    box-shadow: -1px 1px 7px #999;
    padding: 5px;
    // width: 350px;
    width: 90vw;
    text-align: center;
    >p{
        padding: 5px;
        border-bottom: 1px solid #ddd;
    }
    &.show{
        transform: translateY(0);
        transition: transform .5s;
    }
}
.add_info_pd{
    display: flex;
    padding: 5px 0;
    align-items: center;
    font-size: 12px;
    border-bottom: 1px solid #ddd;
    img{
        width: 80px;
    }
    p{
        width: calc((100% - 80px) / 3);
    }
}
.add_info_btn{
    display: block;
    margin: 10px auto 5px auto;
    width: 100px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background:chocolate;
    font-size: 13px;
    color: #fff;
    border-radius: 10px;
}

.popup_pageCover{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #000;
    opacity: .8;
}

.popup1{
    // .pageCover{
    //     display: block;
    //     z-index: 100;
    // }
    .popup_pageCover{
        display: block;
        z-index: 100;
    }
    .popup_pdBox{
        display: flex;
        z-index: 101;
    }
} 

.popup2{
    // .pageCover{
    //     display: block;
    //     z-index: 200;
    // }
    .popup_pageCover{
        display: block;
        z-index: 200;
    }
    .popup_pdCnt{
        display: flex;
        z-index: 201;
    }
} 

@media (min-width: $tablet-width) {
    .popup_pdCnt{
        .popup_slick_esc{
            position: absolute;
            right: 0;
            top: 0;
        }
    }
    .add_info{
        position: absolute;
        top: 0;
        right: 0;
        width: 350px;
    }
}

@media (min-width: $desktop-width){
    .pdCntWrap{
        width: 900px;
    }
}