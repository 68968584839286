.shoplist {
    display: none;
    &.active {
        display: block;
    }
}

#shopadd {
    border-bottom: 1px solid #888;
    padding-top: 20px;
    // margin: 0 15px;
    .pdbox {
        .pdbox_img{
            display: none;
            &.active{
                display: block;
            }
        }
        .pdcnt_info_color {
            margin-bottom: 10px;
            li {
                width: 30px;
                height: 30px;
            }
        }
        .pdcnt_info_size,
        .pdcnt_info_number {
            margin-bottom: 10px;
            display: flex;
            div {
                &:first-child {
                    width: 40px;
                    // display: inline-block;
                }
                &.selectwrap.active {
                    width: calc(100% - 50px);
                    // display: inline-block;
                }
            }
        }
    }
    // .pdbox_img{
    //     position: relative;
    //     display: block;
    //     img{
    //         position: absolute;
    //         top: 0;
    //         left: 0;
    //         opacity: 0;
    //         &.active{
    //             opacity: 1;
    //         }
    //         &:nth-child(1){
    //             position: static;
    //         }
    //     }
    // }
    .btn {
        //width: 100%;
        width: calc(100% - 50px);
        margin-left: 40px;
        height: 35px;
        line-height: 35px;
    }
}

.addBoxWrap {
    margin: 0 15px;
}

.addBox {
    display: table;
    width: 100%;
    margin: 0 0 10px;
}

.addBox_left {
    width: calc(50% - 10px);
    display: table-cell;
    img {
        width: 100%;
    }
}

.addBox_right {
    display: table-cell;
    vertical-align: top;
    padding: 0 0 0 10px;
    p {
        font-size: 15px;
        margin: 0 0 10px;
        &.addBox_name {
            font-weight: bold;
        }
    }
}

.orderdata{
    margin: 0 15px;
}

.subtotal{
    li{
        display: flex;
        justify-content: space-between; 
    }
}

//以下修改
.orderdata2 {
    margin: 0 15px;
    .pageTitle {
        margin: 45px 0 15px;
    }
}
.orderdata_invoice{
    display: none;
    &.active{
        display: block;
    }
}
.subtotal2 {
    span {
        display: inline-block;
        width: calc((100% - 35px)/2);
        &:first-child {
            margin-right: 30px;
            text-align: right;
            font-weight: bold;
        }
    }
    // li {
    //     &:last-child {
    //         font-size: 16px;
    //         line-height: 33px;
    //     }
    // }
    &.subtotal2_final{//new
        li {
            font-size: 16px;
            line-height: 33px;
        }
    }
}

.orderFinal {
    margin: 30px 15px;
    label {
        font-size: 14px;
    }
    a {
        text-decoration: underline;
    }
}

.orderfin {
    max-width: 480px;
    margin: 0 15px;
}

.orderfin2 {
    max-width: calc(100% - 30px);
    margin: 0 15px;
}

.orderInfo_area{
    .inputwrap{
        select.tel{
            width: 85px;
            margin-right: 5px;
        }
        input.tel{
            width: calc(100% - 95px);
        }
        &.inputwrap_addr{
            display: none;
            &.active{
                display: block;
                &.overseas{
                    .select_city, .select_area{
                        display: none;
                    }
                }
            }
        }
        &.inputwrap_store{
            display: none;
            &.active{
                display: block;
            }
        }
    }
}
.invoiceCnt {
    display: none;
    &.active {
        display: block;
    }
    .inputwrap.inputwrap_addr{
        display: flex;
    }
}

.storeChioce {
    display: flex;
    justify-content: space-between;
    .btn_second {
        width: 48%;
        padding: 0 12px;
    }
}

.storeAddr {
    display: none;
    padding-top: 15px;
    &.active {
        display: block;
    }
}
.storeAddr_info{
    width: 100%;
    // padding: 10px 0 20px;
    padding: 10px 0 0;
}
.rNotice {
    margin-bottom: 20px;
    .notice_title {
        background-color: #ddd;
        width: 100%;
        padding: 5px;
        text-align: center;
        font-weight: bold;
        font-size: 16px;
    }
}
.invoice_area{
    textarea{
        display: block;
        width: 100%;
    }
}
@media (min-width: 480px) {
    .orderdata_box2 {
        .inputwrap {
            width: 450px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .orderfin {
        margin: 0 auto;
    }
    .orderfin2 {
        max-width: 75%;
        margin: 0 auto;
    }
}

@media (min-width: 600px) {
    .addBoxWrap {}
    .addBox {
        width: calc((100% - 30px)/2);
        float: left;
        &:nth-child(2n+1) {
            margin-right: 30px;
        }
    }
}

@media (min-width: $tablet-width) {
    .orderdata_box2 {
        vertical-align: top;
        padding: 0 15px;
        .pageTitle{
            text-align: left;
        }
    }
    .payway_area{
        display: flex;
        .inputwrap {
            width: calc((100% - 30px) / 4);
        }
        .inputwrap+.inputwrap{
            margin-left: 10px;
        }
    }
    .discount_area{
        .inputwrap {
            width: 100%;
            display: flex;
            margin-left: 0;
            label{
                width: 100px;
            }
            select, input{
                width: 250px;
                margin-right: 10px;
            }
            .btn_second{
                margin-top: 0;
            }
        }
    }
    .orderInfo_area{
        .inputwrap {
            width: 100%;
            display: flex;
            margin-left: 0;
            label{
                width: 100px;
            }
            select, input{
                width: calc((100% - 30px) / 3);
                margin-right: 10px;
            }
            select.tel{
                margin-right: 10px;
            }
            input + select, select + select{
                margin-top: 0;
            }
            select + input{
                width: calc(100% - 10px);
            }
            select + input.tel{
                margin-top: 0;
                width: calc((100% - 30px) / 3 - 95px);
            }
            textarea{
                width: calc(100% - 110px);
            }
            &.inputwrap_addr{
                &.active{
                    display: flex;
                }
            }
            &.inputwrap_store{
                flex-wrap: wrap;
                &.active{
                    display: flex;
                }
            }
            .inputwrap_addr_home{
                display: flex;
                flex-wrap: wrap;
                width: calc(100% - 100px);
                max-width: calc(260px * 3);
            }
            .storeChioce{
                width: 320px;
                .btn_second {
                   margin-top: 0; 
                }
            }
            .storeAddr{
                display: none;
                flex-wrap: wrap; 
                margin-left: 100px;
                width: calc(100% - 100px);
                max-width: calc(260px * 3);
                padding-bottom: 0;
                &.active{
                    display: flex;
                }
                select{
                    margin-right: 10px;
                    width: calc((100% - 30px) / 3);
                }
                select + select{
                    margin-right: 10px;
                }
            }
        }
    }
    .storeAddr_info{
        width: calc(100% - 100px);
        margin-left: 100px;
        p, .has-text-right{
            width: 100%;
        }
    }

    .invoice_area{
        .inputwrap {
            width: 100%;
            display: flex;
            margin-left: 0;
            label{
                width: 100px;
                &.longer{
                    width: 200px;
                }
            }
            select, input{
                width: 250px;
                margin-right: 10px;
            }
            &.inputwrap_addr{
                
            }
            .inputwrap_addr_home{
                display: flex;
                flex-wrap: wrap;
                width: calc(100% - 100px);
                max-width: calc(260px * 3);
                select, input{
                    width: calc((100% - 30px) / 3);
                    margin-right: 10px;
                }
                input + select, select + select{
                    margin-top: 0;
                }
                select + input{
                    width: calc(100% - 10px);
                }
            }
        }
    }
    .subtotal2 {
        li {
            span {
                &:first-child {
                    width: 120px;
                }
                &:last-child {
                    width: calc(100% - 150px);
                } 
            }
        }
        &.subtotal2_final{//new
            li {
                font-size: 18px;
            }
        }
    }
}
@media (min-width: $desktop-width){
    .orderInfo_area{
        .inputwrap {
            select, input{
                width: 250px;
            }
            select + input{
                max-width: calc(260px * 3);
            }
            select + input.tel{
                max-width: calc(250px - 95px);
            }
            .storeAddr{
                select{
                    max-width: 250px;
                }
            }
        }
    }
    .storeAddr_info{
        p, .has-text-right{
            max-width: calc(260px * 3);
        }
    }
    .invoice_area{
        textarea{
            max-width: 870px;
        }
        .inputwrap {
            input, select{
                max-width: 250px;
            }
        }
    }
}