/*------------------------------------*\
  #Generic
\*------------------------------------*/

body {
    // font-family: "微軟正黑體";//new
    width: 100vw;
    box-sizing: border-box;
    color: $main_color;
    font-family: "NotoSansTC", Arial, sans-serif;
}

a {
    color: $main_color;
    text-decoration: none;
    &:focus,
    &:active {
        color: $hover_a_color;
    }
    &:focus{
        outline:none;
    }
}

input {
    color: $main_color;
    line-height: 24px;
    font-size: 16px;
}

input[type="checkbox"] {
    border-radius: 0;
}

.custom-label-flipswitch.ui-flipswitch .ui-btn.ui-flipswitch-on {
    text-indent: -3.4em;
}

.custom-label-flipswitch.ui-flipswitch .ui-btn.ui-flipswitch-off {
    text-indent: 0.5em;
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="password"],
input[type="date"],
textarea {
    -webkit-appearance: none;
    border: solid 1px $line_gray;
    border-radius: 0;
    padding: 3px 10px;
    &:focus{
        outline:none;
    }
}

select {
    // -webkit-appearance: none;
    color: $main_color;
    border: solid 1px $line_gray;
    border-radius: 0;
    padding: 3px 10px;
    font-size: 16px;
    line-height: 24px;
    height: 32px;
    background: #fff;
    // outline:none;
    // option{
    //     outline:none !important;
    //     &:focus{
    //         outline:none;
    //     }
    // }
    // background: url(../../images/zh-cht/icons/select.png) no-repeat right top,#fff;
    // &:focus {
    // background-position: right bottom;
    // }
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

section {
    margin: 0 0 45px;
}

.wrap {
    position: relative;
    width: 100vw;
    overflow-y: hidden;
}

.mainContent {
    transform: translateX(0);
    transition: transform .5s;
    width: 100vw;
    // min-height: calc(100vh - #{$header_height});
    // min-height: calc(100vh - #{$header_height} - 410px);
    // position: fixed;
    // top: $header_height;
    // left: 0;
    padding: $header_height 0 0;
}

.contentWrap {
    min-height: calc(100vh - #{$header_height} - #{$touchFooter_height});
}

.pageCover {
    width: 100vw;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    left: 0;
    top: 0;
    // transition: transform .5s;
    display: none;
    z-index: 4;
}

.mmenu-opened {
    .mainContent {
        transform: translateX(80vw);
    }
    .pageCover {
        display: block;
    }
}

.secTitle {
    letter-spacing: 2px;
    margin: 45px 0 15px;
}

.btnWrap {
    text-align: center;
    padding: 30px 0;
    &.btnWrap-two {
        margin: 0 15px;
        .btn,
        .btn_second {
            width: calc((100% - 10px - 5px)/2);
            padding: 0;
            margin: 0 10px 0 0;
            &:last-child {
                margin: 0;
            }
        }
        .btn_second{
            background: #c5bab5;
        }
    }
}

.btnWrap_add {
    margin: 0 0 15px;
    a {
        font-size: 14px;
        text-decoration: underline;
        &.btn {
            text-decoration: none;
        }
    }
    label {
        font-size: 14px;
        input {
            margin: 0 10px 0 0;
        }
    }
}

.btnWrap-full {
    margin: 15px 0;
    .btn {
        width: 100%;
        margin: 0 0 5px;
    }
}

.inputwrap+.btnWrap-full {
    margin: 30px 0 15px;
}

.btn {
    display: inline-block;
    //background: #333;
    background: #3d3a36;
    color: #fff;
    font-size: 14px;
    line-height: 45px;
    padding: 0 15px;
    text-align: center;
    &:hover {
        color: #fff;
    }
    &.btn-fb {
        background: #3B5998;
        // svg {
        //     vertical-align: middle;
        // }
        &:hover {
            background: #446BBC;
        }
    }
    &.btn-google {
        background: #dd3826;
        &:hover {
            background: #e05243;
        }
    }
    &.btn-line {
        background: #00bc00;
        .line_text {
            font-weight: bold;
            font-size: 16px;
        }
        &:hover {
            background: #2ace2a;
        }
    }
    i {
        margin: 0 3px 0 0;
    }
}

.btn_second {
    display: inline-block;
    background: #dfdfdf;
    color: #333;
    font-size: 14px;
    line-height: 45px;
    padding: 0 15px;
    text-align: center;
    i {
        margin: 0 3px 0 0;
    }
}

.checkboxwrap {
    padding: 5px 0;
    label {
        display: block;
        font-size: 14px;
        input[type="checkbox"] {
            vertical-align: middle;
            margin: 0 5px 0 0;
        }
    }
}

.carousel {
    margin: 0 0 30px;
    .pdbox {
        margin: 0 15px;
    }
    .slick-prev,
    .slick-next {
        z-index: 2;
        // width: 25px;
        // height: 50px;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        border: 1px solid $main_black;
        text-align: center;
        text-align: center;
        &:before {
            display: none;
        }
    }
    .slick-prev {
        left: 5px;
        background: #fff;
        //background: url(../../images/zh-cht/icons/left-arrow.png) no-repeat center center/ 100% auto;
        &:after{
            display: inline-block;
            content: "";
            width: 8px;
            height: 8px;
            margin-left: 3px;
            border-top: 1px solid $main_black;
            border-left: 1px solid $main_black;
            transform: rotate(-45deg);  
        }
    }
    .slick-next {
        right: 5px;
        background: #fff;
        //background: url(../../images/zh-cht/icons/right-arrow.png) no-repeat center center/ 100% auto;
        &:after{
            display: inline-block;
            content: "";
            width: 8px;
            height: 8px;
            margin-right: 3px;
            border-top: 1px solid $main_black;
            border-right: 1px solid $main_black;
            transform: rotate(45deg);  
        }
    }
}

.pdbox {
    img {
        width: 100%;
    }
}

.pdbox_name {
    margin: 5px 0 10px;
    font-size: 0.9em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.pdbox_price {}

.pdbox_price-origin {
    text-decoration: line-through;
    font-size: 0.8em;
}

.pdbox_price-sale {
    // color: $orange;
    color: #bc0303;
}

.selectwrap {
    position: relative;
    display: inline-block;
    border: solid 1px $line_gray;
    &:after {
        content: '';
        display: block;
        position: absolute;
        width: 10px;
        height: 16px;
        background: url(../../images/zh-cht/icons/right-arrow.png) no-repeat center center/100% auto;
        transform: rotateZ(90deg);
        right: 10px;
        top: 8px;
        pointer-events: none;
    }
    select {
        width: 100%;
        appearance: none;
        // padding: 0 45px 0 15px;
        padding: 0 30px 0 10px;
        border-radius: 0;
        line-height: 30px;
        height: 30px;
        border: 0;
        // font-size: 1em;
        font-size: 14px;
        background: #fff;
    }
}

.breadcrumb {
    padding: 10px 15px;
    // background: $bg_gray;
    font-size: .9em;
    li {
        display: inline-block;
        position: relative;
        padding-right: 22px;
        &:after {
            content: '';
            display: block;
            position: absolute;
            width: 6px;
            height: 30px;
            background: url(../../images/zh-cht/icons/right-arrow.png) no-repeat center/100% auto;
            right: 4px;
            top: 0px;
        }
        &:last-child {
            &:after {
                display: none;
            }
            a {
                color: #333;
            }
        }
    }
    a {
        display: block;
        line-height: 30px;
        color: #888;
    }
}

.pagination_desktop {
    display: none;
}

.pagination {
    padding: 30px 0;
    button {
        appearance: none;
        border: 0;
        background: transparent;
        width: 10px;
        height: 30px;
        vertical-align: middle;
        &:first-child {
            background: url(../../images/zh-cht/icons/left-arrow.png) no-repeat center center/50% auto;
        }
        &:last-child {
            background: url(../../images/zh-cht/icons/right-arrow.png) no-repeat center center/50% auto;
        }
        &.disabled {
            opacity: 0.6;
        }
    }
    a {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        padding: 0 5px;
        margin: 0 5px;
        &:after {
            content: '/';
            position: absolute;
            right: -10px;
            top: 0;
            font-weight: normal;
        }
        &:last-of-type {
            &:after {
                display: none;
            }
        }
        &.active {
            font-weight: bold;
        }
    }
}

.botNotes {
    font-size: 0.8em;
    margin: 2px 0 0;
    &:last-of-type {
        margin: 2px 0 30px 0;
    }
}

.emphasize {
    font-size: 20px;
}

.popup {
    margin: 0 auto;
    padding: 15px;
    background: #fff;
}

.popup_title {
    text-align: center;
    font-size: 1.2em;
    padding: 10px 0;
    margin: 0 0 15px;
}

.stock-ok {
    color: green;
}

.stock-low {
    color: #ee8e04;
}

.stock-zero {
    color: red;
}

.pageTitle {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    margin: 30px;
}
h2.pageTitle.pageTitle_static {
    text-align: left;
    margin-left: 0;
}
.inputwrap {
    margin: 0 0 15px;
    label {
        display: block;
        font-size: 14px;
    }
    input[type="text"],
    input[type="password"],
    input[type="email"],
    input[type="tel"],
    input[type="date"],
    select {
        width: 100%;
        &.error{
            border: 2px solid red;
        }
    }
    &.captcha {
        input {
            width: 78%;
            display: inline-block;
        }
        .captcha_img {
            max-width: 20%;
            display: inline-block;
            vertical-align: top;
        }
    }
    
    .btn,
    .btn_second {
        margin-top: 10px;
        line-height: 35px;
    }
    .inputwrap_note {
        font-size: 12px;
        margin: 0;
    }
    input+select,
    select+select,
    select+input {
        margin-top: 10px;
    }
    .error+select,
    .error+select,
    .error+input {
        margin-top: 10px;
    }
    textarea {
        width: 100%;
        resize: none;
    }
    .onoffswitch {
        display: inline-block;
    }
    .onoffswitch_txt {
        display: inline-block;
        vertical-align: top;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
    }
}

.grayBgTitle {
    color: #fff;
    padding: 0 15px 0 30px;
    margin: 0 15px;
    line-height: 30px;
    //background: url(../../images/zh-cht/icons/label.png) no-repeat 15px center, $mid_gray;
    background: url(../../images/zh-cht/icons/label.png) no-repeat 15px center, #c5bab5;
    &.grayBgTitle-toggle {
        cursor: pointer;
        // margin: 0 15px 30px;
        //background: url(../../images/zh-cht/icons/label.png) no-repeat 15px center, url(../../images/zh-cht/icons/select-white.png) no-repeat right top, $mid_gray;
                background: url(../../images/zh-cht/icons/label.png) no-repeat 15px center, url(../../images/zh-cht/icons/select-white.png) no-repeat right top, #c5bab5;
        &.active {
            //background: url(../../images/zh-cht/icons/label.png) no-repeat 15px center, url(../../images/zh-cht/icons/select-white.png) no-repeat right -27px, $mid_gray;
            background: url(../../images/zh-cht/icons/label.png) no-repeat 15px center, url(../../images/zh-cht/icons/select-white.png) no-repeat right -27px, #c5bab5;
        }
    }
}

.labelTitle {
    padding: 0 0 0 25px;
    color: #333;
    line-height: 35px;
    font-size: 16px;
    border-bottom: solid 1px #333;
    background: url(../../images/zh-cht/icons/label-black.png) no-repeat 5px center;
}

.noteWrap {
    padding: 15px 30px;
    margin: 0 15px;
    border: solid 1px $line_gray;
    .noteWrap_head {
        margin: 0 0 30px;
        font-size: 14px;
        font-weight: bold;
    }
    ul,
    ol {
        padding: 0 0 0 15px;
        margin: 0 0 15px;
    }
    li {
        font-size: 14px;
    }
}

.redTxt {
    color: red;
}

.dtwrap {
    overflow: hidden;
    >.columns {
        margin-top: 0;
    }
}

.dtwrap-small {
    overflow: hidden;
}

//影片
.ytvideo {
    max-width: 485px;
    margin: 0 auto;
}
.embed-container { 
    position: relative; 
    // padding-bottom: 56.25%; 
    padding-bottom: 177.75%; 
    height: 0; 
    overflow: hidden; 
    max-width: 100%; 
} 
.embed-container iframe, 
.embed-container object, 
.embed-container embed { 
    position: absolute; 
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%;
}

//更正ui-icon路徑
.ui-icon,.ui-widget-content .ui-icon{
    background-image:url("fonts/ui-icons_444444_256x240.png");
}
.ui-widget-header .ui-icon{
    background-image:url("fonts/ui-icons_444444_256x240.png");
}
.ui-state-hover .ui-icon,.ui-state-focus .ui-icon,.ui-button:hover .ui-icon,.ui-button:focus .ui-icon{
    background-image:url("fonts/ui-icons_555555_256x240.png");
}
.ui-state-active .ui-icon,.ui-button:active .ui-icon{
    background-image:url("fonts/ui-icons_ffffff_256x240.png");
}
.ui-state-highlight .ui-icon,.ui-button .ui-state-highlight.ui-icon{
    background-image:url("fonts/ui-icons_777620_256x240.png");
}
.ui-state-error .ui-icon,.ui-state-error-text .ui-icon{
    background-image:url("fonts/ui-icons_cc0000_256x240.png");
}
.ui-button .ui-icon{
    background-image:url("../fonts/ui-icons_777777_256x240.png");
}

@media (min-width: 600px) {
    .noteWrap {
        width: 540px;
        margin: 0 auto;
    }
    .btnWrap {
        &.btnWrap-two {
            .btn,
            .btn_second {
                width: 120px;
            }
        }
    }
}

@media (min-width: $tablet-width) {
    .contentWrap {
        min-height: calc(100vh - #{$header_height} - #{$tableFooter_height});
    }
    .pageTitle {
        font-size: 22px;
    }
    // h2.pageTitle.pageTitle_static{
    //     text-align: left;
    //     margin-left: 0;
    // }
    .carousel {
        // width: 760px;
        width: 708px;
        margin-left: auto;
        margin-right: auto;
        .slick-prev {
            left: -20px;
        }
        .slick-next {
            right: -20px;
        }
    }
    .popup {
        width: 500px;
        position: relative;
        padding: 30px;
    }
    .inputwrap {
        label {
            font-size: 16px;
        }
    }
    .pagination {
        display: none;
    }
    .pagination_desktop {
        display: block;
        padding: 30px 0;
        button {
            appearance: none;
            border: 0;
            background: transparent;
            width: 20px;
            height: 30px;
            vertical-align: middle;
            &:first-child {
                background: url(../../images/zh-cht/icons/left-arrow.png) no-repeat center center/50% auto;
            }
            &:last-child {
                background: url(../../images/zh-cht/icons/right-arrow.png) no-repeat center center/50% auto;
            }
            &.disabled {
                opacity: 0.6;
            }
        }
        a {
            display: inline-block;
            vertical-align: middle;
            position: relative;
            padding: 0 5px;
            margin: 0 5px;
            &.active {
                font-weight: bold;
            }
        }
    }
}

@media (min-width: $desktop-width) {
    .mainContent {
        // min-height: calc(100vh - #{$header_height} - #{$mainMenu_height});
        // padding: $headerAndMainMenu_height 0 0;
        padding: $headerAndMainMenu_height2 0 0;
    }
    .contentWrap {
        // min-height: calc(100vh - #{$headerAndMainMenu_height} - #{$desktopFooter_height});
        min-height: calc(100vh - #{$headerAndMainMenu_height2} - #{$desktopFooter_height});
    }
    .dtwrap,
    .dtwrap-mainMenu {
        max-width: 1200px;
        margin: 0 auto;
    }
    // header .dtwrap{
    //     padding-right: 15px;
    // }
    header .header_inner{
        padding-right: 15px;
        padding-left: 15px;
    }
    .dtwrap-small {
        max-width: 760px;
        margin: 0 auto;
    }
    .carousel {
        width: 900px;
    }
    .btn {
        &:hover {
            background: #000;
            color: #fff;
        }
        &:focus,
        &:active {
            color: #fff;
        }
    }
    .btn_second {
        &:hover {
            background: #cfcfcf;
        }
    }
}