/*------------------------------------*\
  #Footer
\*------------------------------------*/
footer {
  font-family: "微軟正黑體";
  //background: #5a5a5a;
  background: #3d3a36;
  color: #fff;
  margin: 30px 0 0;
  padding: 20px;
  text-align: center;
  .column {
    padding-top: 0;
    padding-bottom: 0;
  }
  .columns {
    margin: 0;
  }
}
.footerNav {
  a {
    color: #fff;
    text-align: center;
    display: block;
    font-size: 14px;
    line-height: 40px;
  }
  i {
    pointer-events: none;
  }
  .submenu {
    display: none;
    &.active {
      display: block;
    }
  }
}
.footerSocial {
  text-align: center;
  margin: 30px auto 30px;
  a {
    display: inline-block;
    margin: 0 10px;
    vertical-align: middle;
    &:nth-child(3) {
      img {
        margin-top: 3px;
      }
    }
  }
  i {
    color: #fff;
    font-size: 28px;
  }
}
.footerBottom {
  text-align: center;
  a {
    font-size: 8px;
    color: #aaaaaa;
    text-decoration: none;
    display: inline-block;
    text-align: center;
    width: 100%;
  }
}
.footerLetter {
  // text-align: left;
  p {
    font-weight: bold;
    line-height: 40px;
  }
  label {
    // display: block;
    line-height: 40px;
    font-size: 14px;
  }
  input {
    width: 150px;
  }
  .EDMbtn{
    vertical-align:top;
    //background: #ddd;
    background: #3d3a36;
    //color: #333;
    font-weight: bold;
    height: 30px;
    //line-height: 30px;
    line-height: 28px;
    margin-left: -5px;
    border: solid 1px #aaa;
    border-left: none;
  }
}

@media (min-width: $tablet-width) {
  footer {
    .columns {
      margin: 0 auto;
    }
  }
  .footerNav {
    a{
      text-align: left;
      padding-left: 35%;
    }
    i {
      display: none;
    }
    > li {

      > a {
        font-weight: bold;
      }
    }
    .submenu {
      display: block;
    }
  }
  .footerSocial {
    margin: 60px auto 40px auto;
  }
}