/*------------------------------------*\
  #pdlist
\*------------------------------------*/
.pdlist_banner {
  img {
    width: 100%;
  }
}
.pdlist_func {
  padding: 30px 15px;
  label {
    margin: 0 10px 0 0;
    display: none;
    font-size: .9em;
  }
  span {
    font-size: .8em;
    margin-left: 60px;
    // display: block;
  }
  .selectwrap{
    &:after{
      top: 7px;
      width: 8px;
      height: 12px;
    }
  }
  select{
    height: 25px;
    line-height: 25px;
    font-size: .9em;
  }
}
.pdlist_wrap {
  margin: 0 15px;
  .columns {
    display: flex;
    flex-wrap: wrap;
  }
  .pdbox {
    box-sizing: border-box;
  }

}
@media (min-width: $tablet-width) {
  .pdlist_func {
    label {
      display: inline-block;
    }
    span {
      display: inline;
    }
  }
}
@media (min-width: $desktop-width) {
  .pdlist_func {/*sonia 0130*/
    // padding: 30px 0;

  }
}