.member {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
}

.memberNav {
    position: relative; // padding-top: 45px;
    margin-top: 30px;
    a {
        font-size: 14px;
        line-height: 30px;
    }
    ul {
        display: none;
        padding: 0 0 0 15px;
        border-left: solid 1px $line_gray;
        border-right: solid 1px $line_gray;
        border-bottom: solid 1px $line_gray;
        &.active {
            display: block;
        }
    }
    li {
        &.active {
            a {
                color: #000000;
                font-weight: bold;
                text-decoration: underline;
            }
        }
    }
    .staticNav {
        ul {
            padding: 10px 0 10px 15px;
            display: none;
        }
        &.active {
            ul {
                display: block;
            }
        }
    }
    .labelTitle {
        border-bottom: 0;
    }
}

.memberNav_toggle {
    border: solid 1px $line_gray;
    display: block;
    padding: 0 15px;
    background: url(../../images/zh-cht/icons/select.png) no-repeat right 3px, #fff;
    &.active {
        background-position: right -25px;
    }
}

.memberCnt {
    // width: calc(100% - 365px);
    // display: inline-block;
    // vertical-align: top;
    padding-bottom: 45px;
    .column {
        // padding: 0 30px;
    }
    .cartbox {
        margin: 0;
        width: 100%;
    }
    .total {
        margin: 0 0 30px;
        box-shadow: 0 1px 1px 1px rgba(0, 0, 0, .3);
    }
}

.greet {
    text-align: center;
    margin: 0 0 30px;
}

.dataTable {
    width: 100%;
    border-collapse: collapse;
    border-bottom: solid 1px $mid_gray;
    tr {
        &:nth-child(2n) {
            background: $bg_gray;
        }
        &:nth-child(2n+1) {
            background: #fff;
        }
    }
    th {
        background: $mid_gray;
        color: #fff;
        font-weight: normal;
    }
    td,
    th {
        padding: 10px;
        font-size: 14px;
        height: 41px;
        &.pdinfo_txt{
            width: 30%;
        }
    }
    td.noorder {
        text-align: center;
    }
    .return_r{
        width: 100%;
        display: block;
        +.return_r{
            margin-top: 10px;
        }
    }
    a {
        text-decoration: underline;
        color: $main_blue;
        display: inline-block;
    }
    &.dataTable-return {
        margin: 0 0 45px;
        border-bottom: 0;
    }
}

.faq {
    margin: 30px 0;
}

.faq-q,
.faq-a {
    padding: 0 30px 18px;
    p {
        color: #333;
        font-size: 14px;
    }
    span {
        display: inline-block;
        color: $mid_gray;
        font-size: 12px;
        margin-left: 5px;
    }
}

.faq-q {
    background: url(../../images/zh-cht/icons/q.png) no-repeat left top;
    p {
        color: #333;
    }
    &:first-of-type {
        padding: 18px 30px;
        background-position: left 18px;
    }
}

.faq-a {
    background: url(../../images/zh-cht/icons/a.png) no-repeat left top;
    p {
        color: $main_blue;
    }
}

.return_note {
    height: 100px;
}

.applyReturnMoney {
    margin-top: 15px;
}

.dataList {
    margin: 0 0 45px;
    span {
        display: inline-block;
        width: 60%;
        box-sizing: border-box;
        font-size: 14px;
        line-height: 30px;
        &:first-child {
            width: 40%;
            color: $mid_gray;
            text-align: right;
            padding: 0 30px 0 0;
            vertical-align: top;
        }
    }
    a {
        text-decoration: underline;
        color: $main_blue;
    }
}

#eInvoice {
    text-align: center;
}

.return_msg {
    background: $bg_gray;
    padding: 15px;
    margin: 0 0 45px;
    p {
        font-size: 14px;
        span {
            color: #000;
            font-weight: bold;
        }
    }
}

.discountSendWrap {
    width: 100%;
    max-width: 550px;
    margin: 20px auto;
}

.discountSendWrap .discountSend {
    width: calc(100% - 80px);
}

.discountSendWrap .btn_discount {
    display: inline-block;
    width: 75px;
    text-align: center;
    vertical-align: top;
    background: #ddd;
    color: #333;
    font-weight: bold;
    // height: 38px;
    // line-height: 38px;
    height: 32px;
    line-height: 30px;
    border: solid 1px #aaa;
    border-left: none;
}

@media (max-width: 950px) {
    .dataTable {
        th {
            display: none;
        }
        td {
            display: block;
            box-sizing: border-box;
            text-align: center;
            position: relative; // padding-left: 50%;
            padding-left: 35%;
            height: auto;
            min-height: 41px;
            &.pdinfo_txt{
                width: 100%;
            }
            &:before {
                content: attr(data-th);
                display: inline-block;
                text-align: center; // width: 50%;
                width: 35%;
                position: absolute;
                left: 0;
                font-weight: bold;
            }
            &:last-child {
                padding-bottom: 20px;
            }
            // .return_r {
            //     width: 100%;
            // }
            &.noorder {
                padding-left: 0;
                text-align: center;
            }
        }
    }
}

@media (min-width: $tablet-width) {
    .member {
        margin-left: -0.75rem;
        margin-right: -0.75rem;
    }
    .memberNav {
        display: block;
        background: $bg_gray;
        padding: 15px 30px 15px;
        margin-top: 30px; // margin-left: 30px;
        a {
            font-size: 16px;
            line-height: 32px;
        }
        ul {
            display: block;
            border: 0;
        }
        .staticNav {
            ul {
                padding: 0 0 10px 23px;
                display: block;
            }
        }
    }
    .memberCnt {
        padding-left: 30px;
        padding-right: 30px;
        .column {
            padding: 0 30px;
        }
    }
    .dataList {
        margin: 0 30px 45px;
        li {
            float: left;
            width: 50%;
        }
        span {
            &:first-child {
                text-align: left;
            }
        }
    }
    .discountSendWrap .btn_discount {
        height: 30px;
        // line-height: 30px;
        line-height: 28px;
    }
}

@media (min-width: $desktop-width) {
    .memberNav {
        margin-left: 15px;
    }
}