$main_color: #333;

/*文字色*/

$hover_a_color: #333;

/*a連結滑上文字*/

$icon_color: #333;

/*登入, 購物車, saerch, 手機menu -- 顏色 */

$orange: #fe6905;
$main_blue: #114085;
$main_black: #333;
$line_gray: #aaa;
$mid_gray: #888;
$bg_gray: #f3f3f3;
$wrap_width: 1200px;
$primary: $main_black;
$header_height: 50px;
$header_height2: 80px;//65px
$mainMenu_height: 40px;
$headerAndMainMenu_height: 90px;//$header_height+40px;
$headerAndMainMenu_height2: 120px;//$header_height2+40px
$touchFooter_height: 279px;
$tableFooter_height: 460px;
$desktopFooter_height: 460px;
// $input_padding: 10px;
// $td_padding: 10px;
// $normal_font_size: 14px;
// $normal_font_color: #555;
//for media query
$tablet-width: 768px;
$desktop-width: 1008px;
$desktop-wide-width: 1200px;