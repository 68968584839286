.newslist {
    margin-bottom: 60px;
    > .columns {
        border-bottom: solid 1px $line_gray;
        margin-left: 15px;
        margin-right: 15px;
        &:first-child {
            border-top: solid 1px $line_gray;
        }
        .column {
            padding: 0.75rem 0;
            // align-items: center;
            justify-content: center;
            display: flex;
            flex-direction: column;
            &:first-child {
                padding-bottom: 0;
            }
        }
    }
    img {
        width: 100%;
    }
    h6 {
        font-weight: bold;
        font-size: 15px;
    }
    p {
        font-size: 13px;
    }
    span {
        font-size: 10px;
        color: $mid_gray;
    }
}
.news {
    .pageTitle {
        text-align: left;
        margin: 30px 15px 0;
    }
    article {
        margin: 30px 15px 60px;
        
    }
}
.news_date {
    color: $mid_gray;
    font-size: 12px;
    margin: 0 0 0 15px;
}
@media (min-width: $tablet-width) {
    .newslist {
        h6 {
            font-weight: bold;
            font-size: 16px;
        }
        p {
            font-size: 14px;
        }
        span {
            font-size: 12px;
        }
        > .columns {
            .column {
                padding: 0.75rem;
                &:first-child {
                    padding-left: 0;
                    padding-bottom: 0.75rem;
                }
            }
        }
    }
    img.newslist_img{
        width: 200px;
    }
}
