/*------------------------------------*\
  #pdcontent
\*------------------------------------*/

.pdcnt_img {}

.pdcnt_img_group {
    display: none;
    .slick-slider {
        margin-bottom: 15px;
    }
    &.active {
        display: block;
    }
}

.pdcnt_img_group_main {
    margin: 0 0 15px;
}

.pdcnt_img_group_thumb {
    display: none;
    margin: 0 15px;
    img {
        margin: 0 10px;
    }
    .slick-prev,
    .slick-next {
        z-index: 1;
        width: 10px;
        height: 30px;
        &:before {
            display: none;
        }
    }
    .slick-prev {
        background: url(../../images/zh-cht/icons/left-arrow.png) no-repeat center center/100% auto;
        left: -9px;
    }
    .slick-next {
        background: url(../../images/zh-cht/icons/right-arrow.png) no-repeat center center/100% auto;
        right: -9px;
    }
}

.pdcnt_info {
    padding: 15px;
    //text-align: center;
    margin: 0 0 30px;
    h1 {
        //font-size: 1.1em;
        font-size: 1em;
        // font-weight: bold;
        // span {
        //     font-size: .7em;
        //     font-weight: normal;
        //     display: block;
        // }
    }
}

.pdcnt_info_price {
    padding: 15px 0;
    font-size: 14px;
    line-height: 30px;
}

.pdcnt_info_price-origin {
    text-decoration: line-through;
    //margin: 0 10px;
    margin-right: 10px;
}

.pdcnt_info_price-sale {
    // font-size: 1.1em;
    // color: $orange;
    color: #bc0303;
}

.pdcnt_info_event {
    margin: 0 0 15px;
    a {
        display: inline-block;
        padding: 0 10px;
        line-height: 30px;
        // background: #c5bab5;
        background: #750202;
        color: #fff;
        // font-size: .9em;
        font-size: 14px;
    }
}

.pdcnt_info_color {
    text-align: left;
    margin: 0 0 30px;
    >div {
        p {
            // font-size: 0.9em;
            font-size: 14px;
            line-height: 30px;
            span {
                font-weight: normal;
                margin: 0 0 0 10px;
            }
        }
    }
    ul {
        margin: 5px 0 0;
    }
    li {
        display: inline-block;
        width: 32px;
        height: 32px;
        padding: 3px;
        margin: 0 3px 0 0;
        &.active {
            border: solid 1px #333;
        }
        a {
            display: block;
        }
        img {
            width: 100%;
        }
    }
}

.pdcnt_info_size {
    text-align: left;
    margin: 0 0 30px;
    >div {
        &:first-child {
            margin: 0 0 10px;
            span {
                // font-size: 0.9em;
                font-size: 14px;
                line-height: 30px;
            }
            a {
                float: right;
                text-decoration: underline;
            }
        }
    }
    .selectwrap {
        width: 100%;
        display: none;
        &.active {
            display: block;
        }
    }
}

.pdcnt_info_number {
    text-align: left;
    margin: 0 0 30px;
    >div {
        &:first-child {
            margin: 0 0 10px;
            span {
                //font-weight: bold;
                font-size: 0.9em;
            }
            a {
                float: right;
                text-decoration: underline;
                font-size: 0.9em;
            }
        }
    }
    .selectwrap {
        width: 100%;
    }
}

.pdcnt_info_date {
    display: none;
}

.pdcnt_info_date.show {
    display: block;
}

.pdcnt_info_btn {
    .btn,
    .btn_second {
        width: 100%;
        margin: 0 0 15px;
    }
    .btn_cart{
        background: #3d3a36;
    }
    .btn_wish{
        color: #fff;
        background: #b0a7a2;
    }
    .btn_pdarrived {
        display: none;
    }
    .btn_pdsoldout {
        display: none;
    }
    &.empty {
        .btn_pdsoldout {
            display: block;
        }
        .btn_pdarrived {
            display: none;
        }
        .btn_cart {
            display: none;
        }
    }
    &.notify {
        .btn_pdarrived {
            display: block;
        }
        .btn_pdsoldout {
            display: none;
        }
        .btn_cart {
            display: none;
        }
    }
}

.shareBox {
    // text-align: center;
    display: flex;
    justify-content: center;
}

.fbBox,
.lineBox,
.twitterBox,
.igBox {
    display: inline-block;
    margin-right: 10px;
    &:last-child{
        margin-right: 0;
    }
    i{
        font-size: 30px;
    }
}

.igBox {
    margin-right: 0;
}
//.lineBox {
//    vertical-align: top;
//}

.pdcnt_gifts {
    padding: 0 15px;
    margin: 0 0 30px;
    width: 100%;
    &:before {
        content: '';
        display: table;
        width: 100%;
    }
    ul {
        border-top: solid 1px $line_gray;
        border-bottom: solid 1px $line_gray;
    }
    img {
        width: 30%;
        display: inline-block;
        margin: 0 10px 0 0;
    }
    li {
        padding: 15px 0;
        font-size: .9em;
    }
    div {
        display: inline-block;
        width: 60%;
        vertical-align: top;
        padding: 10px 0;
    }
}

.pdcnt_gifts_title{
    //font-weight: bold;
    //font-size: 1.2em;
    font-size: 1.1em;
    margin: 0 0 10px;
    //text-align: center;
}
.pdcnt_details_title{
    //font-size: 1.2em;
    font-size: 1.1em;
    margin: 0 0 10px;
    text-align: center;
}

.pdcnt_details {
    padding: 0 15px;
    margin: 0 0 30px;
    // .columns {
    //     margin: 0;
    // }
    // .column {
    //     padding: 0;
    //     >a {
    //         text-align: center;
    //         display: block;
    //         line-height: 40px;
    //         position: relative;
    //         border-bottom: solid 1px $line_gray;
    //         &:after {
    //             content: '+';
    //             position: absolute;
    //             top: 0;
    //             right: 5px;
    //             line-height: 40px;
    //         }
    //         &.active {
    //             &:after {
    //                 content: '-';
    //             }
    //         }
    //     }
    //     &:first-child {
    //         a {
    //             border-top: solid 1px $line_gray;
    //         }
    //     }
    // }
}

// .pdcnt_details_cnt {
//     padding: 15px;
//     display: none;
//     border-bottom: solid 1px $line_gray;
//     &.active {
//         display: block;
//     }
// }

.pdcnt_tab_nav{
    border-left: 1px solid $line_gray;
    border-bottom: 1px solid $line_gray;
    margin-top: 20px;
    margin-bottom: 10px;
    li{
        float: left;
        padding: 10px 15px;
        text-align: center;
        border-top: 1px solid $line_gray;
        border-right: 1px solid $line_gray;
        font-size: 1rem;
        cursor: pointer;
        &.active{
            background: #b0a7a2;
            color: #fff;
        }
    }
}

.pdcnt_tab_cnt{
    display: none;
    padding: 15px;
    &.active{
        display: block;
    }
}
.tab_sizeguide{
    width: 100%;
    max-width: 800px;
    border-bottom: solid 1px $line_gray;
    th {
        border-bottom: solid 1px $line_gray;
        border-top: solid 1px $line_gray;
    }
    td,
    th {
        padding: 10px;
        font-size: 14px;
        text-align: center;
    }
    .botNotes {
        text-align: right;
    }
    
}
.pdcnt_social {
    p {
        font-weight: bold;
        font-size: 1.2em;
    }
}

.pdcnt_useredit {
    margin: 0 0 30px;
}

// #sizeguide,
#stockstatus {
    table {
        width: 100%;
        border-bottom: solid 1px $line_gray;
    }
    th {
        border-bottom: solid 1px $line_gray;
        border-top: solid 1px $line_gray;
    }
    td,
    th {
        padding: 10px;
        font-size: 14px;
        text-align: center;
    }
    .botNotes {
        text-align: right;
    }
}

#stockstatus {
    td,
    th {
        &:last-child {
            text-align: left;
        }
    }
}

#message {
    text-align: center;
    // background: transparent;
    // color: #fff;
    // input{
    //   border: 1px solid #fff;
    //   background: transparent;
    // }
    // .messageBtn{
    //   color: #333;
    //   background: #ddd;
    //   width: 50px;
    //   height: 38px;
    //   display: inline-block;
    //   vertical-align: top;
    //   line-height: 38px;
    //   text-align: center;
    //   margin-left: -5px;
    // }
    // .mfp-close{
    //   color: #fff
    // }
}

.pdcnt_carousel {
    padding: 0 15px;
    margin: 0 0 30px;
    width: 100%;
}

@media (min-width: $tablet-width) {
    .pdcnt_img {
        float: left;
        width: 50%;
        padding: 30px 15px 0;
        img {
            width: 100%;
        }
    }
    .pdcnt_img_group_thumb {
        display: block;
    }
    .pdcnt_info {
        float: left;
        width: 50%;
        padding: 30px 30px 0;
        h2 {
            font-size: 1.3em;
        }
    }
    .pdcnt_info_color {
        li {
            width: 30px;
            height: 30px;
        }
    }
    .pdcnt_gifts {
        li {
            width: 50%;
            float: left;
        }
    }
    .pdcnt_gifts_title,
    .pdcnt_details_title {
        text-align: left;
    }
    .pdcnt_details {
        .column {
            padding: 0;
            border-bottom: solid 1px $line_gray;
            margin: 0 15px 0 0;
            &:nth-child(3) {
                margin: 0;
            }
            >a {
                border-top: solid 1px $line_gray;
                &:after {
                    display: none;
                }
            }
        }
    }
    // .pdcnt_details_cnt {
    //     display: block;
    //     border-bottom: 0;
    // }
    .pdcnt_tab_nav{
        li{
            padding: 10px 20px;
            font-size: 1rem;
        }
    }

    .fb-share-button {
        float: right;
    }
    .line-it-button {
        float: right;
        margin-right: 15px;
    }
    // #message{
    //   .messageBtn{
    //     height: 30px;
    //     line-height: 30px;
    //   }
    // }
}

@media (min-width: $desktop-width) {
    // .pdcnt_img_group_main {
    //   float: left;
    //   width: 80%;
    // }
    // .pdcnt_img_group_thumb {
    //   float: left;
    //   width: 20%;
    //   margin: 15px 0;
    //   img {
    //     margin: 10px;
    //   }
    //   .slick-prev, .slick-next {
    //     left: 50%;
    //     margin-left: -4px;
    //     transform: rotateZ(90deg);
    //     margin-top: 0;
    //   }
    //   .slick-prev {
    //     top: -15px;
    //   }
    //   .slick-next {
    //     top: auto;
    //     bottom: -15px;
    //   }
    // }
    .pdcnt_info {
        text-align: left;
    }
    .pdcnt_info_btn {
        .btn {
            margin: 0 0 15px;
        }
    }
}